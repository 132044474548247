<template>
  <v-container fluid>
      <div class="tables-basic">
          <h1 class="page-title mt-10 mb-6">Ustawienia</h1>

          <ErrorSubscription :isCustomerActive="isCustomerActive()" />

          <v-row v-if="showGlobalSettingsModals && isCustomerActive()">
              <v-col cols="5">
                  <v-card>
                      <v-card-title class="pa-6 pb-3">
                          <p>Dane firmy/instytucji</p>
                          <v-spacer></v-spacer>
                          <v-btn v-if="user.type == 1" @click="changeCustomerData()" style="margin-left:50px;" color="blue">Zmień dane</v-btn>
                      </v-card-title>
                      <v-card-text class="pa-3 pb-0" center>Nazwa: <span style="font-weight: bold;">{{customer.companyName}}</span> </v-card-text>
                      <v-card-text class="pa-3 pb-0">Adres: <span style="font-weight: bold;">{{customer.companyAddress}}</span></v-card-text>
                      <v-card-text class="pa-3 pb-0">Kod pocztowy: <span style="font-weight: bold;">{{customer.companyZipcode}}</span></v-card-text>
                      <v-card-text class="pa-3 pb-0">Miasto: <span style="font-weight: bold;">{{customer.companyCity}}</span></v-card-text>
                      <v-card-text class="pa-3 pb-0">Nip: <span style="font-weight: bold;">{{customer.nip}}</span></v-card-text>
                      <v-card-text class="pa-3 pb-0">Status: <span style="font-weight: bold;">{{customer.status == 1 ? 'Aktywny' : 'Nieaktywny'}}</span></v-card-text>
                      <v-card-text class="pa-3 pb-3">Ważność konta do: <span style="font-weight: bold;">{{formatDateToDisplay(customer.validTo)}}</span></v-card-text>
                  </v-card>
              </v-col>
              <v-col cols="3">
                  <v-card>
                      <v-card-title class="pa-6 pb-3">
                          <p>Logo/Herb</p>
                          <v-spacer></v-spacer>
                          <v-btn v-if="user.type == 1" @click="changeCustomerLogo()" style="margin-left:10px;" color="blue">Zmień</v-btn>

                      </v-card-title>

                      <div class="image-ram">
                          <v-img v-if="logo.name == null || logo.name.length == 0" src="@/assets/no-image.png" class="client-logo"></v-img>
                          <v-img v-else :src="logo.name" class="client-logo"></v-img>
                      </div>
                      <div class="remove-logo">
                          <v-btn v-if="user.type == 1 && logo.name != null && logo.name.length > 0" @click="removeCustomerLogo()"><v-icon color="red">mdi-trash-can</v-icon>Usuń</v-btn>
                      </div>
                  </v-card>
              </v-col>
              <v-col cols="4">
                  <v-card>
                      <v-card-title class="pa-6 pb-3">
                          <p>Twoje konto</p>
                          <v-spacer></v-spacer>
                          <v-btn @click="changePassword()" style="margin-left:50px;">Zmień hasło</v-btn>
                      </v-card-title>
                      <v-card-text class="pa-3 pb-0" center>Imię i nazwisko: <span style="font-weight: bold;">{{user.name}}</span></v-card-text>
                      <v-card-text class="pa-3 pb-0">email: <span style="font-weight: bold;">{{user.email}}</span></v-card-text>
                      <v-card-text class="pa-3 pb-0">Telefon: <span style="font-weight: bold;">{{user.phone}}</span></v-card-text>
                      <v-card-text class="pa-3 pb-0">Data rejestracji: <span style="font-weight: bold;">{{formatDateToDisplay(user.registrationDate)}}</span></v-card-text>
                      <v-card-text class="pa-3 pb-0">Typ: <span style="font-weight: bold;">{{user.type == 1 ? 'Administrator' : 'Użytkownik'}}</span></v-card-text>
                      <v-card-text class="pa-3 pb-0">Możesz archiwizować zgłoszenia: <span style="font-weight: bold;">{{user.canArchivize ? 'Tak' : 'Nie'}}</span></v-card-text>
                      <v-card-text class="pa-3 pb-3">Możesz pobierać raporty: <span style="font-weight: bold;">{{user.canReports ? 'Tak' : 'Nie'}}</span></v-card-text>
                  </v-card>
              </v-col>
          </v-row>
          <v-row v-if="customer.packageId > 1 && showGlobalSettingsModals && isCustomerActive()">
              <v-col cols="12">
                  <v-card class="pa-3 pb-3">
                      <v-card-title class="pa-6 pb-0">
                          <p>Dodatkowe rodzaje zgłoszeń</p>
                          <v-spacer></v-spacer>
                          <v-btn v-if="user.type == 1" @click="changeSygnalTypes()" style="margin-left:50px;">Dostosuj</v-btn>
                      </v-card-title>
                      <v-row>
                          <v-col md="4">
                              <v-card-text class="pa-3 pb-0" center><span style="font-weight: bold;">Telefoniczne</span></v-card-text><hr />
                              <v-card-text class="pa-3 pb-0">Status: <span style="font-weight: bold;">{{types.phoneEnabled ? 'Aktywne' : 'Nieaktywne'}}</span></v-card-text>
                              <v-card-text class="pa-3 pb-0">Kategoria zgłoszeń telefonicznych: <span style="font-weight: bold;">{{types.selectedPhoneCategoryName == null ? 'Brak' : types.selectedPhoneCategoryName}}</span></v-card-text>
                              <v-card-text class="pa-3 pb-0">Indywidualny PIN firmy/instytucji: <span style="font-weight: bold;">{{types.pin == null ? 'Brak' : types.pin}}</span></v-card-text>
                              <v-card-text class="pa-3 pb-0">Nr telefonu dla zgłoszeń: <span style="font-weight: bold;">{{types.phonenumber == null ? 'Brak' : types.phonenumber}}</span></v-card-text>
                          </v-col>
                          <v-col md="4">
                              <v-card-text class="pa-3 pb-0" center><span style="font-weight: bold;">Email</span></v-card-text><hr />
                              <v-card-text class="pa-3 pb-0">Status: <span style="font-weight: bold;">{{types.mailEnabled ? 'Aktywne' : 'Nieaktywne'}}</span></v-card-text>
                              <v-card-text class="pa-3 pb-0">Kategoria zgłoszeń e-mail: <span style="font-weight: bold;">{{types.selectedMailCategoryName == null? 'Brak' : types.selectedMailCategoryName}}</span></v-card-text>
                              <v-card-text class="pa-3 pb-3">Adres email dla zgłoszeń: <span style="font-weight: bold;">{{types.email == null? 'Brak' : types.email}}</span></v-card-text>
                          </v-col>
                      </v-row>

                  </v-card>
              </v-col>
          </v-row>
          <v-row v-if="showUserPasswordModal && isCustomerActive()">
              <v-col cols="12">
                  <v-card class="pa-3 pb-3">
                      <v-card-title class="pa-6 pb-1">
                          <p>Zmiana hasła</p>
                          <v-spacer></v-spacer>
                          <v-btn @click="closeModal()">Zamknij</v-btn>
                      </v-card-title>
                      <form class="pa-10 pb-10">
                          <v-text-field v-model="oldpassword"
                                        :error-messages="passRules"
                                        type="password"
                                        autocomplete="off"
                                        label="Stare hasło"
                                        @input="$v.oldpassword.$touch()"
                                        @blur="$v.oldpassword.$touch()"
                                        required></v-text-field>
                          <v-text-field v-model="newpassword"
                                        :error-messages="passRulesNew"
                                        type="password"
                                        autocomplete="off"
                                        label="Nowe hasło"
                                        @input="$v.newpassword.$touch()"
                                        @blur="$v.newpassword.$touch()"
                                        required></v-text-field>
                          <v-text-field v-model="repeatnewdpassword"
                                        :error-messages="passRulesRepeat"
                                        type="password"
                                        autocomplete="off"
                                        label="Powtórz nowe hasło"
                                        @input="$v.repeatnewdpassword.$touch()"
                                        @blur="$v.repeatnewdpassword.$touch()"
                                        required></v-text-field>
                          <br /><br />
                          <v-btn class="mr-4"
                                 @click="submit" color="green" :disabled="oldpassword.length < 6 || newpassword.length < 6 || repeatnewdpassword.length < 6 || newpassword != repeatnewdpassword">
                              Zatwierdź
                          </v-btn>
                          <v-btn @click="clear">
                              Wyczyść pola
                          </v-btn>
                      </form>
                  </v-card>
              </v-col>
          </v-row>
          <v-row v-if="showCustomerDataModal && user.type == 1 && isCustomerActive()">
              <v-col cols="12">
                  <v-card class="pa-3 pb-3">
                      <v-card-title class="pa-6 pb-1">
                          <p>Zmiana danych korespondencyjnych</p>
                          <v-spacer></v-spacer>
                          <v-btn @click="closeModalCustomerData()">Zamknij</v-btn>
                      </v-card-title>
                      <form class="pa-10 pb-10">
                          <v-text-field v-model="customerName"
                                        :error-messages="nameErrors"
                                        label="Nazwa firmy"
                                        required
                                        @input="$v.customerName.$touch()"
                                        @blur="$v.customerName.$touch()"></v-text-field>
                          <v-text-field v-model="adress"
                                        :error-messages="adressErrors"
                                        label="Adres"
                                        required
                                        @input="$v.adress.$touch()"
                                        @blur="$v.adress.$touch()"></v-text-field>
                          <v-text-field v-model="zipcode"
                                        :error-messages="zipcodeErrors"
                                        label="Kod pocztowy format(XX-XXX)"
                                        required
                                        @input="$v.zipcode.$touch()"
                                        @blur="$v.zipcode.$touch()"></v-text-field>
                          <v-text-field v-model="city"
                                        :error-messages="cityErrors"
                                        label="Miasto"
                                        required
                                        @input="$v.city.$touch()"
                                        @blur="$v.city.$touch()"></v-text-field>
                          <v-text-field v-model="nip"
                                        :error-messages="nipErrors"
                                        label="NIP"
                                        @keypress="onlyNumber"
                                        @input="$v.nip.$touch()"
                                        @blur="$v.nip.$touch()"></v-text-field>
                          <br /><br />
                          <v-btn class="mr-4"
                                 @click="submitCustomerData" color="green" :disabled="customerName.length < 3 || adress.length < 3 || zipcode.length < 5 || city.length < 3">
                              Zatwierdź
                          </v-btn>
                      </form>
                  </v-card>

              </v-col>
          </v-row>

          <v-row v-if="showStgnalTypesModal && user.type == 1 && isCustomerActive() && customer.packageId > 1">
              <v-col cols="12">
                  <v-card class="pa-3 pb-3">
                      <v-card-title class="pa-6 pb-1">
                          <p>Edycja dodatkowych zgłoszeń</p><v-spacer></v-spacer>
                          <v-btn @click="closeModalSygnalTypes()">Zamknij</v-btn>

                      </v-card-title>
                      <form class="pa-10 pb-10">
                          <v-row>
                              <v-col md="3">
                                  <v-checkbox v-model="phoneActive"
                                              label="Zgłoszenia telefoniczne aktywne?"
                                              @change="$v.phoneActive.$touch()"
                                              @blur="$v.phoneActive.$touch()"></v-checkbox>
                              </v-col>
                              <v-col md="5">
                                  <v-card-text>Wybierz kategorię dla zgłoszeń telefonicznych:</v-card-text>
                                  <v-select v-model="selectedPhoneCategory" :items="categories" item-text="catName" item-value="catId" dense :disabled="!phoneActive"></v-select>
                              </v-col>
                          </v-row>
                          <br /><hr />
                          <v-row>
                              <v-col md="3">
                                  <v-checkbox v-model="mailActive"
                                              label="Zgłoszenia e-mail aktywne?"
                                              @change="$v.mailActive.$touch()"
                                              @blur="$v.mailActive.$touch()"></v-checkbox>
                              </v-col>
                              <v-col md="5">
                                  <v-card-text>Wybierz kategorię dla zgłoszeń e-mail:</v-card-text>
                                  <v-select v-model="selectedMailCategory" :items="categories" item-text="catName" item-value="catId" dense :disabled="!mailActive"></v-select>
                              </v-col>
                          </v-row>
                          <br /><br />
                          <v-btn class="mr-4"
                                 @click="submitSygnalTypesData" color="green">
                              Zatwierdź
                          </v-btn>
                      </form>
                  </v-card>

              </v-col>
          </v-row>
      </div>
  </v-container>
</template>

<script>
    import Service from '@/services/Service'
    import { validationMixin } from 'vuelidate'
    import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators'
    import ErrorSubscription from '@/components/Modals/ErrorInfoSubscription.vue'
export default {
name: 'Settings',
components: {
    ErrorSubscription
},
mixins: [validationMixin],
validations: {
    customerName: { required, minLength: minLength(3) },
    adress: { required, minLength: minLength(3) },
    city: { required, minLength: minLength(3) },
    nip: { minLength: minLength(10), maxLength: maxLength(10) },
    zipcode: { required, minLength: minLength(5) },
    oldpassword: { required, minLength: minLength(6) },
    newpassword: { required, minLength: minLength(6) },
    repeatnewdpassword: { required, minLength: minLength(6), sameAsPassword: sameAs('newpassword') },
    phoneActive: {
        checked(val) {
            return val
        },
    },
    mailActive: {
        checked(val) {
            return val
        },
    },
},
  data() {
    return {
        customer: {},
        user: {},
        main: [],
        logo: {
            id: 0,
            name: ''
        },
        showCustomerDataModal: false,
        showUserPasswordModal: false,
        showStgnalTypesModal: false,
        oldpassword: '',
        newpassword: '',
        repeatnewdpassword: '',
        customerName: '',
        zipcode: '',
        adress: '',
        city: '',
        nip: '',
        types: {},
        categories: [],
        phoneActive: false,
        mailActive: false,
        selectedPhoneCategory: {},
        selectedMailCategory: {},
        showGlobalSettingsModals: true
    }
  },
computed: {
    nameErrors() {
        const errors = []
        if (!this.$v.customerName.$dirty) return errors
        !this.$v.customerName.minLength && errors.push('Pole nie może mieć mniej niż 3 znaki')
        !this.$v.customerName.required && errors.push('Pole jest wymagane.')
        return errors
    },
    cityErrors() {
        const errors = []
        if (!this.$v.city.$dirty) return errors
        !this.$v.city.minLength && errors.push('Pole nie może mieć mniej niż 3 znaki')
        !this.$v.city.required && errors.push('Pole jest wymagane.')
        return errors
    },
    adressErrors() {
        const errors = []
        if (!this.$v.adress.$dirty) return errors
        !this.$v.adress.minLength && errors.push('Pole nie może mieć mniej niż 3 znaki')
        !this.$v.adress.required && errors.push('Pole jest wymagane.')
        return errors
    },
    zipcodeErrors() {
        const errors = []
        if (!this.$v.zipcode.$dirty) return errors
        !this.$v.zipcode.minLength && errors.push('Pole nie może mieć mniej niż 5 znaków')
        !this.$v.zipcode.required && errors.push('Pole jest wymagane.')
        return errors
    },
    nipErrors() {
        const errors = []
        if (!this.$v.nip.$dirty) return errors
        !this.$v.nip.minLength && errors.push('Pole nie może mieć mniej niż 10 znaków')
        !this.$v.nip.maxLength && errors.push('Pole nie może mieć więcej niż 10 znaków')
        return errors
    },
    passRules() {
    const errors = []
        if (!this.$v.oldpassword.$dirty) return errors
        !this.$v.oldpassword.minLength && errors.push('Pole nie może mieć mniej niż 6 znaków')
    return errors
    },
    passRulesNew() {
        const errors = []
        if (!this.$v.newpassword.$dirty) return errors
        !this.$v.newpassword.minLength && errors.push('Pole nie może mieć mniej niż 6 znaków')
        return errors
    },
    passRulesRepeat() {
        const errors = []
        if (!this.$v.repeatnewdpassword.$dirty) return errors
        !this.$v.repeatnewdpassword.minLength && errors.push('Pole nie może mieć mniej niż 6 znaków')
        !this.$v.repeatnewdpassword.sameAsPassword && errors.push('Hasła nie są takie same')
        return errors
    }
},
async mounted() {
    try {

        if (this.isCustomerActive()) {
            this.main = (await Service.getCustomerData()).data,
                this.customer = this.main.customer,
                this.logo = this.main.customer.logo,
                this.user = this.main.user,
                this.types = this.main.types
        }

    } catch (e) {
        if (e.response.status == 401)
            this.$router.push({ name: 'Login' })
    }
  },
  methods: {
      isCustomerActive() {
          return this.$store.state.admin.customerIsActive;
      },
      changeSygnalTypes() {
          this.categories = this.types.categories;
          this.phoneActive = this.types.phoneEnabled;
          this.mailActive = this.types.mailEnabled;
          this.selectedPhoneCategory = { catName: this.types.selectedPhoneCategoryName, catId: this.types.selectedPhoneCategoryId };
          this.selectedMailCategory = { catName: this.types.selectedMailCategoryName, catId: this.types.selectedMailCategoryId };
          this.showGlobalSettingsModals = false;
          this.showStgnalTypesModal = true;
      },
      closeModalSygnalTypes() {
          this.categories = [];
          this.selectedPhoneCategory = {};
          this.selectedMailCategory = {};
          this.phoneActive = false;
          this.mailActive = false;
          this.showStgnalTypesModal = false;
          this.showGlobalSettingsModals = true;
      },
      async submitSygnalTypesData() {
          try {
              if (confirm('Czy zapisać zmiany?')) {
                  if (typeof this.selectedMailCategory !== "number") {
                      if (this.selectedMailCategory.catId == null) {
                          this.selectedMailCategory = 0;
                      } else {
                          this.selectedMailCategory = this.selectedMailCategory.catId;
                      }
                  }
                  if (typeof this.selectedPhoneCategory !== "number") {
                      if (this.selectedPhoneCategory.catId == null) {
                          this.selectedPhoneCategory = 0;
                      } else {
                          this.selectedPhoneCategory = this.selectedPhoneCategory.catId;
                      }
                  }

                  if (this.mailActive && this.selectedMailCategory == 0) {
                      alert("Nie wybrano kategorii dla zgłoszeń email");
                      return;
                  }

                  if (this.phoneActive && this.selectedPhoneCategory == 0) {
                      alert("Nie wybrano kategorii dla zgłoszeń telefonicznych");
                      return;
                  }

                  var temp = {
                      id: this.types.id,
                      phoneEnabled: this.phoneActive,
                      mailEnabled: this.mailActive,
                      selectedPhoneCategoryId: this.selectedPhoneCategory,
                      selectedMailCategoryId: this.selectedMailCategory,
                      customerId: this.customer.id,
                  };

                  var result = (await Service.changeSygnalTypesData(temp)).data;
                  if (Object.keys(result).length > 0) {
                      this.types.phoneEnabled = result.phoneEnabled;
                      this.types.mailEnabled = result.mailEnabled;
                      this.types.selectedPhoneCategoryName = result.selectedPhoneCategoryName;
                      this.types.selectedPhoneCategoryId = result.selectedPhoneCategoryId;
                      this.types.selectedMailCategoryId = result.selectedMailCategoryId;
                      this.types.selectedMailCategoryName = result.selectedMailCategoryName;
                      this.types.pin = result.pin;
                      this.types.email = result.email;
                      this.phonenumber = result.phonenumber;
                      this.types.id = result.id;

                      this.closeModalSygnalTypes();
                  }
              }
              else {
                  return;
              }
          } catch (e) {
              alert(e.response.status + " - " + e.response.data.toString())
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      },
      async submitCustomerData() {
          try {
              if (confirm('Czy zmienić dane korespondencyjne?')) {

                  if (this.nip.length > 0) {
                      if (this.nip.length != 10) {
                          alert("Błędny numer NIP");
                          return;
                      }
                  }

                  var temp = {
                      id: this.customer.id,
                      companyName: this.customerName,
                      companyZipcode: this.zipcode,
                      companyAddress: this.adress,
                      companyCity: this.city,
                      nip: this.nip
                  };

                  var result = (await Service.changeCustomerDataForAdmin(temp)).data;
                  if (Object.keys(result).length > 0) {
                      this.customer.companyName = result.companyName;
                      this.customer.companyZipcode = result.companyZipcode;
                      this.customer.companyAddress = result.companyAddress;
                      this.customer.companyCity = result.companyCity;
                      this.customer.nip = result.nip;
                      this.showCustomerDataModal = false;
                      this.showGlobalSettingsModals = true;
                  }
              }
              else {
                  return;
              }
          } catch (e) {
              alert(e.response.status + " - " + e.response.data.toString())
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      },
      onlyNumber($event) {
          let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
          if ((keyCode < 48 || keyCode > 57)) {
              $event.preventDefault();
          }
      },
      closeModalCustomerData() {
          this.customerName = '';
          this.zipcode = '';
          this.adress = '';
          this.city = '';
          this.nip = '';
          this.showCustomerDataModal = false;
          this.showGlobalSettingsModals = true;
      },
      async submit() {
          try {
              if (confirm('Czy zmienić hasło do tego konta? Po zmianie hasła nastąpi wylogowanie z systemu.')) {

                  var temp = {
                      id: this.user.id,
                      customerId: this.customer.id,
                      password: this.oldpassword,
                      passwordNew1: this.newpassword,
                      passwordNew2: this.repeatnewdpassword
                  };

                  var result = (await Service.changePasswordForAdmin(temp));
                  if (result.status == 200) {
                      var res = (await Service.logout());
                      if (res.status == 200) {
                          this.$router.push('/login');
                      }
                  }

                  this.newpassword = '';
                  this.oldpassword = '';
                  this.repeatnewdpassword = '';
                  this.showGlobalSettingsModals = true;
              }
              else {
                  return;
              }
          } catch (e) {
              alert(e.response.status + " - " + e.response.data.toString())
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      },
      clear() {
          this.newpassword = '';
          this.oldpassword = '';
          this.repeatnewdpassword = '';
      },
      closeModal() {
          this.newpassword = '';
          this.oldpassword = '';
          this.repeatnewdpassword = '';
          this.showUserPasswordModal = false;
          this.showGlobalSettingsModals = true;
      },
      changeCustomerData() {
          this.customerName = this.customer.companyName;
          this.zipcode = this.customer.companyZipcode;
          this.adress = this.customer.companyAddress;
          this.city = this.customer.companyCity;
          this.nip = this.customer.nip;
          this.showGlobalSettingsModals = false;
          this.showCustomerDataModal = true;
      },
      async saveElement(files) {
          try {
              let formData = new FormData();
              for (var i = 0; i < files.length; i++) {
                  let file = files[i];
                  formData.append('files[' + i + ']', file);
              }

              var result = (await Service.saveCustomerLogoByAdmin(formData)).data;
              if (Object.keys(result).length > 0) {
                  this.logo = result;
              }
          } catch (e) {
              alert(e.response.status + " - " + e.response.data.toString());

              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      },
      async removeCustomerLogo() {
          try {
              var result = (await Service.removeCustomerLogoByAdmin());
              if (result.status == 200) {
                  this.logo = {
                      id: 0,
                      name: ''
                  };
              }
              else {
                  this.logo = {
                      id: 0,
                      name: ''
                  };
              }
          } catch (e) {
              alert(e.response.status + " - " + e.response.data.toString());

              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      },
      changeCustomerLogo() {
          let input = document.createElement('input');
          input.type = 'file';
          input.accept = '.png';
          input.multiple = false;
          input.click();
          input.addEventListener('change', (event) => {
              if (event.target.files[0].size <= 5000000) {
                  if (this.checkMimeType(event.target.files[0].type)) {
                      this.saveElement(event.target.files);
                  }
                  else {
                      alert("Nieobsługiwany typ pliku. Pliki obsługiwane to: PNG")
                  }
              } else {
                  alert("Zbyt duży rozmiar pliku! Suma załączników nie może przekraczać 5MB");
              }
          });
      },
      checkMimeType(type) {
          if (type != "image/png") {
              return false;
          } else {
              return true;
          }
      },
      changePassword() {
          this.showGlobalSettingsModals = false;
          this.showUserPasswordModal = true;
      },
      formatDateToDisplay(value) {
          var date = new Date(value)
          var d = date.toLocaleString('default', {
              year: 'numeric',
              month: 'long',
              weekday: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric'
          });

          return d;
      },
  }
}

</script>

<style scope>
    .client-logo {
        width: 150px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        top: 50%;
        transform: translateY(-50%);
    }

    .image-ram {
        width: 200px;
        height: 200px;
        margin-left: auto;
        margin-right: auto;
    }

    .remove-logo {
        padding: 7px;
        text-align: center;
    }

</style>
